/* You can add global styles to this file, and also import other style files */
@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

@import "app/theme/styles/base";
@import "app/theme/styles/calendar";

.inner-content {
  overflow: auto !important;
}

html,
body {
  height: 100%;
}
body {
  margin: 0;
  font-family: Roboto, sans-serif;
  font-size: 16px;
}

.app-error {
  font-size: 12px;
  color: rgb(225, 29, 72);
}

@keyframes blink {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.blink {
  animation: blink 0.3s ease-in-out;
}

.ant-message-custom-content {
  display: flex;
  align-items: center;
}
.ant-message-custom-content .anticon {
  padding-bottom: 3px;
}
